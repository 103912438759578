.menu-burger {
  display: none;

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    position: fixed;
    z-index: 1;
    right: 8px;
    bottom: 8px;
    display: flex;
    overflow: hidden;
    width: 55px;
    height: 55px;
    justify-content: center;
    align-items: center;
    background: rgba(19, 19, 21, .6);
    border-radius: 50%;
    backdrop-filter: blur(100px);
  }

  &:before {

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      display: block;
      width: 24px;
      height: 15px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H23' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M1 7.5H23' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M1 14H23' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }

  &.is-active {

    &:before {

      @media only screen and (max-width: theme('screens.mdMax.max')) {
        width: 17px;
        height: 17px;
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L15.9995 15.9995' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M1 15.9996L15.9995 1.00004' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
