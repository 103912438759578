.menu {
  margin: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 64px;

  @media only screen and (max-width: theme('screens.xlMax.max')) {
    gap: 32px;
  }

  @media only screen and (max-width: theme('screens.lgMax.max')) {
    gap: 16px;
  }

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    position: fixed;
    z-index: 1;
    right: 8px;
    bottom: 70px;
    padding: 13px 8px 15px;
    display: none;
    width: 200px;
    flex-direction: column;
    align-items: stretch;
    background: rgba(19, 19, 21, .6);
    border-radius: 20px;
    backdrop-filter: blur(100px);
    gap: 10px;
  }

  a {
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: -.3px;
    color: theme('colors.gray');
    transition: var(--transition-base);

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      padding: 7px 8px;
      display: block;
      font-size: 16px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: -.3px;
      color: theme('colors.secondary');
      background: transparent;
      border-radius: 6px;
    }

    &.is-active,
    &:hover {
      color: theme('colors.secondary');

      @media only screen and (max-width: theme('screens.mdMax.max')) {
        color: theme('colors.gray') !important;
        background-color: theme('colors.secondary');
      }
    }
  }
}
