.badge {
  margin: 0;
  padding: 0;
  display: inline-flex;
  min-height: 34px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-image: linear-gradient(#131315, #131315), var(--primary-gradient);
  background-clip: content-box, border-box;
  background-origin: border-box;
  border: double 2px transparent;
  border-radius: 50px;

  span {
    padding: 0 16px;
    background: var(--primary-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
