.main {
  //padding: 80px 0 0;
  flex: 1 0 auto;

  @media only screen and (min-width: theme('screens.md')) {
  }

  @media only screen and (max-width: theme('screens.mdMax.max')) {
  }
}
