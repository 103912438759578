#videoSequence1 .video-sequence {
  width: 100%;
  height: calc(100vh - 40px);

  @media only screen and (min-width: theme('screens.md')) {
    position: sticky;
    top: 20px;
    left: 0;
    height: calc(100vh - 40px) !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //object-position: top;
    border-radius: 30px;
  }
}

#videoSequence2 .video-sequence {
  width: 100%;

  @media only screen and (min-width: theme('screens.md')) {
    height: calc(100vh - 40px) !important;
  }

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    height: 254px !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //object-position: top;
    border-radius: 30px;
  }
}

#videoSequence3 {

  @media only screen and (min-width: theme('screens.md')) {
  }

  @media only screen and (max-width: theme('screens.mdMax.max')) {
  }

  .video-section__video-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //object-position: top;
  }
}

#videoSequence4 .video-sequence {
  margin-top: -100px;
  width: 100%;
  height: auto !important;

  @media only screen and (min-width: theme('screens.md')) {
  }

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    margin-top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //object-position: top;
  }
}

.animate-icon1 {
  transform: scale(.5);
  //transition: all .2s ease !important;

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    transform: scale(1);
  }
}

.animate-icon2 {
  transform: rotate(40deg);
  //transition: all .2s ease !important;

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    transform: rotate(0deg);
  }
}

.animate-cards {
  position: relative;
  $offset: 45px;
  $z_index: 10;

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    $offset: 16px;
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    //-webkit-transform: translate3d(0, 0, 0);

    &:not(:first-of-type) {
      position: absolute;
      top: 0;
      left: 0;
    }

    &:nth-of-type(1) {
      z-index: $z_index;
    }

    @for $i from 1 through 9 {

      &:nth-of-type(#{$i + 1}) {
        z-index: #{$z_index - $i};
        //left: #{$offset * $i};

        @media only screen and (max-width: theme('screens.mdMax.max')) {
          left: #{$offset * $i};
        }
      }
    }
  }
}

.video-section {
  position: relative;
  z-index: 2;
  padding: 20px 16px;
  height: 3800px;

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    padding: 20px 0;
    height: 1900px;
  }

  .video-container {
    position: sticky;
    z-index: 2;
    top: 20px;
    left: 0;
    width: 100%;
    height: 970px;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      height: 568px;
    }
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    transform: translate(-50%, -50%);
    transform-origin: center center;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      border-radius: 0;
    }
  }

  &__logo {
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 13px;
    width: 32px;
    opacity: 0;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      top: 11%;
      right: 6%;
      width: 14%;
    }
  }

  &__mc-logo {
    position: absolute;
    z-index: 1;
    top: 104px;
    right: 9px;
    width: 48px;
    opacity: 0;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      top: 73%;
      right: 3%;
      width: 21%;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
    }
  }
}

.phone-section {
  position: relative;
  //padding: 20px 16px;
  z-index: 1;
  margin-top: 820px;
  height: 1970px;

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    margin-top: 200px;
    padding: 0 16px;
    height: 1090px;
  }

  &__container {
    position: sticky;
    top: 20px;
    left: 0;
    padding: 80px 0 0;
    overflow: hidden;
    width: 100%;
    height: 970px;
    background: theme('colors.yellow');
    border-radius: 30px;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      padding-top: 48px;
      height: 568px;
    }
  }

  &__img-main {
    position: absolute;
    z-index: 2;
    bottom: -800px;
    left: 50%;
    width: 690px;
    min-width: 690px;
    height: 699px;
    opacity: .4;
    transform: translateX(-50%);

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      width: 338px;
      min-width: 338px;
      height: 342px;
    }

    .phone-section__img-main-phone {
      display: block;
      width: 100%;
      height: 100%;
    }

    .phone-section__img-main-done {
      position: absolute;
      top: 226px;
      right: 326px;
      width: 38px;
      opacity: 0;

      @media only screen and (max-width: theme('screens.mdMax.max')) {
        top: 32%;
        right: 50%;
        width: 6%;
        transform: translateX(50%);
      }
    }
  }

  &__img-bg {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    width: 507px;
    min-width: 507px;
    height: 758px;
    transform: translateX(-50%);

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      display: none;
    }
  }

  &__top-content {
    opacity: 0;
    text-align: center;
  }

  &__badge {
    padding: 2px 20px;
    display: inline-flex;
    min-height: 34px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    color: theme('colors.dark2');
    border: 2px solid theme('colors.primary');
    border-radius: 50px;

    @apply font-inter;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      min-height: 31px;
      font-size: 13px;
    }
  }

  &__heading {
    margin-top: 12px;
    font-size: 80px;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: -1.2px;
    color: theme('colors.dark2');

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      margin-top: 16px;
      font-size: 28px;
      letter-spacing: -.4px;
    }
  }
}
