// lazysizes

/* fade image in after load */
.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity var(--transition-base);
}

.lazyloaded {
  opacity: 1;
  transition: opacity var(--transition-base);
}

.container {
  padding: 0 16px;
}

.container-fluid {
  padding: 0 16px;
}

.btn {
  padding: 2px 24px;
  display: inline-flex;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  color: theme('colors.secondary');
  border: 1px solid theme('colors.secondary');
  border-radius: 50px;
  transition: var(--transition-base);
}

.btn-primary {
  color: theme('colors.dark');
  background: theme('colors.gold');
  border-color: theme('colors.gold');

  &:hover {
    background: #ffc83b;
    border-color: #ffc83b;
  }

  &:active {
    background: #ffc83b;
    border-color: #ffc83b;
  }

  &:disabled {
    opacity: .7;
    cursor: not-allowed;

    &:hover {
      background: theme('colors.gold');
      border-color: theme('colors.gold');
    }
  }
}

.btn-secondary {
  color: theme('colors.dark');
  background: theme('colors.secondary');
  border-color: theme('colors.secondary');

  &:hover {
    background: theme('colors.gold');
    border-color: theme('colors.gold');
  }

  &:active {
    background: theme('colors.gold');
    border-color: theme('colors.gold');
  }

  &:disabled {
    opacity: .7;
    cursor: not-allowed;

    &:hover {
      background: theme('colors.secondary');
    }
  }
}

.btn-outline-primary {
  color: theme('colors.secondary');
  background: transparent;
  border-color: theme('colors.gold');

  &:hover {
    color: theme('colors.dark');
    background: theme('colors.gold');
    border-color: theme('colors.gold');
  }

  &:active {
    color: theme('colors.dark');
    background: theme('colors.gold');
    border-color: theme('colors.gold');
  }

  &:disabled {
    opacity: .7;
    cursor: not-allowed;

    &:hover {
      color: theme('colors.secondary');
      background: transparent;
      border-color: theme('colors.gold');
    }
  }
}

.btn-outline-secondary {
  color: theme('colors.secondary');
  background: transparent;
  border-color: theme('colors.secondary');

  &:hover {
    color: theme('colors.dark');
    background: theme('colors.secondary');
    border-color: theme('colors.secondary');
  }

  &:active {
    color: theme('colors.dark');
    background: theme('colors.secondary');
    border-color: theme('colors.secondary');
  }

  &:disabled {
    opacity: .7;
    cursor: not-allowed;

    &:hover {
      color: theme('colors.secondary');
      background: transparent;
      border-color: theme('colors.secondary');
    }
  }
}

.btn-link {

  &:hover {
  }

  &:active {
  }
}

// Fancybox
.fancybox-is-open .fancybox-bg {
  opacity: .75;
  background: rgba(19, 19, 21, 1);
}

.fancybox-slide {

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    padding: 0;
  }
}
