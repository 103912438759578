.phone-form {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;

  @media only screen and (max-width: theme('screens.mdMax.max')) {
  }

  &__input-wrapper {
    flex: 1 0 0;
  }

  &__input {
    width: 100%;
    height: 64px;

    .iti {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      background: theme('colors.light');
      border: 2px solid rgba(0, 0, 0, .1);
      border-radius: 16px;
      --iti-border-width: 0;
      --iti-spacer-horizontal: 16px;
      --iti-arrow-padding: 12px;
      //--iti-hover-color: rgba(0, 0, 0, 0);

      &--inline-dropdown {

        .iti__dropdown-content {
          bottom: 64px;
          margin-top: 0;
          margin-bottom: 6px;
          overflow: hidden;
          border-radius: 16px;

          &:before {
            position: absolute;
            top: 20px;
            right: 16px;
            display: block;
            width: 20px;
            height: 20px;
            content: '';
            background-color: #c9c9cd;
            cursor: default;
            mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z'/%3E%3C/svg%3E");
            mask-size: 100%;
          }
        }

        .iti__country-list {
          padding: 4px;
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */

          &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }
        }
      }

      &__selected-country {
        background-color: rgba(0, 0, 0, 0);
        border-radius: 16px 0 0 16px;
        transition: background-color var(--transition-base);

        &:hover {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      &__flag {
        border-radius: 50%;
      }

      &__selected-country-primary {
        background-color: rgba(0, 0, 0, 0) !important;
      }

      &__arrow {
        display: none;
      }

      &__selected-dial-code {
        margin-left: 0;
        //padding-left: 12px;
        padding-right: 8px;
        font-size: 20px;
        line-height: 150%;
        font-weight: 500;
        letter-spacing: -.4px;
        color: theme('colors.primary');
      }

      &__tel-input {
        width: 100%;
        height: 100%;
        flex: 1 0 0;
        font-size: 20px;
        line-height: 150%;
        font-weight: 500;
        letter-spacing: -.4px;
        color: theme('colors.primary');
        background: transparent;
        border: 0;

        &::placeholder {
          opacity: 1;
          font-size: 20px;
          line-height: 150%;
          font-weight: 500;
          letter-spacing: -.4px;
          color: theme('colors.gray');
        }
      }

      &__search-input {
        padding: 0 12px;
        width: 100%;
        height: 60px;
        font-size: 16px;
        line-height: 1;
        font-weight: 400;
        color: theme('colors.primary');
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        box-shadow: none;

        &::placeholder {
          opacity: .6;
          font-size: 16px;
          line-height: 1;
          font-weight: 400;
          color: theme('colors.gray');
        }
      }

      &__country-list {

        .iti__flag,
        .iti__country-name,
        .iti__dial-code {
          margin: 0;
        }
      }

      &__country {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        transition: background-color var(--transition-base);

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        &:hover {
          background-color: #f6f6fe;
        }

        &.iti__highlight {
          background-color: #f6f6fe;
        }

        .iti__flag {
          margin-right: 16px;
          order: 1;
        }

        .iti__dial-code {
          margin-right: 24px;
          min-width: 40px;
          order: 2;
          font-size: 16px;
          line-height: 1.2;
        }

        .iti__country-name {
          order: 3;
          font-size: 16px;
          line-height: 1.2;
        }
      }

      &__country-name {
        color: theme('colors.primary');
      }
    }

    input {
    }
  }

  &__input-left {
    padding: 0 12px 0 16px;
    display: flex;
    height: 100%;
    align-items: center;
    gap: 12px;

    img {
      width: 32px;
      min-width: 32px;
      height: 32px;
    }

    span {
      font-size: 20px;
      line-height: 150%;
      font-weight: 500;
      letter-spacing: -.4px;
      color: theme('colors.primary');
    }
  }

  &__btn {
    padding: 2px 32px;
    display: inline-flex;
    min-height: 64px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 150%;
    font-weight: 500;
    letter-spacing: -.4px;
    color: rgb(255, 255, 255);
    background: theme('colors.primary');
    border-radius: 16px;
  }

  &__success {
    display: none;

    span {
      padding: 2px 32px;
      display: flex;
      min-height: 62px;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      line-height: 150%;
      font-weight: 500;
      letter-spacing: -.4px;
      color: theme('colors.green');
      background: rgba(52, 199, 89, .2);
      border-radius: 16px;
    }
  }
}
