.get-app-btn {
  position: fixed;
  z-index: 99;
  bottom: 20px;
  left: 50%;
  padding: 2px 24px;
  display: inline-flex;
  min-height: 41px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  background: rgba(69, 69, 69, .3);
  border-radius: 106px;
  transition: background-color var(--transition-base);
  transform: translateX(-50%);
  backdrop-filter: blur(70px);
  gap: 10px;

  &:hover {
    background-color: theme('colors.gold');
  }

  @media only screen and (min-width: theme('screens.md')) {
    position: fixed;
    bottom: 24px;
    padding: 2px 48px 2px 40px;
    min-height: 60px;
    font-size: 16px;
  }

  img {

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      width: 22px;
      min-width: 22px;
      height: 22px;
    }
  }
}
