.cookie-banner {
  position: fixed;
  z-index: 102;
  bottom: 16px;
  left: 16px;
  padding: 32px;
  display: none;
  width: 100%;
  max-width: 640px;
  background: rgb(255, 255, 255);
  border-radius: 30px;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, .5);

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    bottom: 0;
    left: 0;
    max-width: 100%;
  }

  &__title {
    margin-bottom: 32px;
    font-size: 23px;
    line-height: 120%;
    font-weight: 600;
    text-align: center;
    letter-spacing: -.3px;
    color: theme('colors.primary');

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      margin-bottom: 16px;
      font-size: 16px;
      text-align: left;
      letter-spacing: 0;
    }
  }

  &__text {
    margin-bottom: 40px;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      position: relative;
      margin-bottom: 20px;
      overflow-y: auto;
      max-height: 204px;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &::-webkit-scrollbar {

      @media only screen and (max-width: theme('screens.mdMax.max')) {
        display: none;
      }
    }

    p {
      font-size: 12px;
      line-height: 150%;
      font-weight: 500;
      letter-spacing: -.2px;
      color: theme('colors.gray');

      @media only screen and (max-width: theme('screens.mdMax.max')) {
      }
    }
  }

  &__text-blur {
    position: sticky;
    bottom: 0;
    left: 0;
    margin-top: -20px;
    width: 100%;
    height: auto;
  }

  &__btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      grid-template-columns: 1fr;
    }
  }

  &__btn {
    padding: 2px 12px;
    display: inline-flex;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    border-radius: 50px;
    transition: var(--transition-base);

    &--reject {
      color: theme('colors.dark2');
      background-color: transparent;
      border: 2px solid theme('colors.dark2');

      &:hover {
        color: theme('colors.secondary');
        background-color: theme('colors.gold');
        border-color: theme('colors.gold');
      }
    }

    &--accept {
      background: theme('colors.dark2');
      border: 2px solid theme('colors.dark2');

      &:hover {
        background-color: theme('colors.gold');
        border-color: theme('colors.gold');
      }
    }
  }

  &__close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 17px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.90039L15.9995 16.8532' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M1 16.8536L15.9995 1.90083' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      display: none;
    }
  }

  &__overflow {

    @media only screen and (min-width: theme('screens.md')) {
      display: none !important;
    }

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      position: fixed;
      z-index: 101;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      background: rgba(#131315, .75);
      cursor: pointer;
    }
  }
}
