html {
  height: 100%;
  min-height: 100%;

  @apply font-sans;
}

body {
  display: flex;
  height: 100%;
  min-height: 100%;
  flex-direction: column;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  color: theme('colors.secondary');
  background: theme('colors.primary');
  -webkit-font-smoothing: antialiased;

  @apply font-sans;

  &.fancybox-active {
    height: 100% !important;

    .scrollContainer {
      overflow: hidden;
      height: 100% !important;
    }
  }
}

.scrollContainer {
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
