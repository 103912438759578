.header {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  padding: 8px 16px;
  width: 100%;

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    padding: 4px;
  }

  &.is-sticky {

    .header__box {
    }

    .menu a {
      color: #fff;
    }
  }

  .container-fluid {

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      padding: 0;
    }
  }

  &__box {
    position: relative;
    border-radius: 100px;

    &:before {
      position: absolute;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(19, 19, 21, .6);
      border-radius: 100px;
      backdrop-filter: blur(100px);
    }
  }

  &__box-container {
    position: relative;
    z-index: 2;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      padding: 14px 16px 14px 24px;
    }
  }

  &__logo {
    display: block;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      width: 110px;
    }

    img {

      @media only screen and (max-width: theme('screens.mdMax.max')) {
        width: 100%;
        height: auto;
      }
    }
  }
}
