.faq {
  display: flex;
  flex-direction: column;

  &__item {
    border-bottom: 3px solid rgba(127, 127, 144, .3);

    &.is-open {

      .faq__question-icon {
        transform: rotate(45deg);
      }
    }
  }

  &__question {
    padding: 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 40px;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      padding: 24px 0;
      gap: 28px;
    }

    &-text {
      font-size: 23px;
      line-height: 107%;
      font-weight: 500;
      letter-spacing: -.5px;
      color: rgb(255, 255, 255);

      @media only screen and (max-width: theme('screens.mdMax.max')) {
        font-size: 16px;
        line-height: 142%;
        letter-spacing: -.2px;
      }
    }

    &-icon {
      width: 22px;
      min-width: 22px;
      height: 23px;
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.486 12.72H12.12V22.17H9.39V12.72H0.0660003V10.2H9.39V0.75H12.12V10.2H21.486V12.72Z' fill='white'/%3E%3C/svg%3E%0A");
      transition: var(--transition-long);
    }
  }

  &__answer {
    padding: 8px 0 32px;
    display: none;

    @media only screen and (max-width: theme('screens.mdMax.max')) {
      padding: 0 0 24px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      opacity: 0;
      transition: var(--transition-long);
    }

    p {
      max-width: 810px;
      font-size: 18px;
      line-height: 150%;
      font-weight: 500;
      letter-spacing: -.4px;
      color: #7c7e84;

      @media only screen and (max-width: theme('screens.mdMax.max')) {
        font-size: 15px;
        letter-spacing: -.3px;
      }
    }
  }
}
