.video {
  position: sticky;
  top: 20px;
  left: 0;
  width: 100%;
  height: calc(100vh - 40px);
  border-radius: 30px;
  object-fit: cover;

  @media only screen and (min-width: theme('screens.md')) {
    height: calc(100vh - 40px) !important;
  }
}

#video1 {

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    position: static !important;
    //height: 700px !important;
  }
}

#video2 {

  @media only screen and (max-width: theme('screens.mdMax.max')) {
    height: 254px !important;
  }
}

#video4 {
  position: static;
  margin-top: -100px;
  height: auto !important;
  border-radius: 0;
}

#video4_mobile {
  position: static;
  height: auto !important;
  border-radius: 0;
}
