h1,
.h1 {
  font-size: 24px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -.4px;

  @media only screen and (min-width: theme('screens.md')) {
    font-size: 80px;
    letter-spacing: -1.2px;
  }

  &.h1--type2 {
    font-size: 28px;
    line-height: 120%;
    font-weight: 600;
    letter-spacing: -.4px;

    @media only screen and (min-width: theme('screens.md')) {
      font-size: 80px;
      letter-spacing: -1.2px;
    }
  }
}

h2,
.h2 {
  font-size: 27px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -.4px;

  @media only screen and (min-width: theme('screens.md')) {
    font-size: 60px;
    letter-spacing: -.9px;
  }

  &.h2--type2 {
    font-size: 28px;
    line-height: 120%;
    font-weight: 600;
    letter-spacing: -.4px;

    @media only screen and (min-width: theme('screens.md')) {
      font-size: 59px;
      line-height: 89%;
      letter-spacing: -.9px;
    }
  }

  &.h2--type3 {
    font-size: 20px;
    line-height: 120%;
    font-weight: 600;
    letter-spacing: -.3px;

    @media only screen and (min-width: theme('screens.md')) {
      font-size: 59px;
      line-height: 89%;
      letter-spacing: -.9px;
    }
  }
}

h3,
.h3 {
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -.3px;

  @media only screen and (min-width: theme('screens.md')) {
    font-size: 30px;
    letter-spacing: -.4px;
  }

  &.h3--type2 {
    font-size: 30px;
    line-height: 142%;
    font-weight: 500;
    letter-spacing: -.4px;
  }
}

h4,
.h4 {
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -.3px;

  @media only screen and (min-width: theme('screens.md')) {
    font-size: 28px;
    letter-spacing: -.4px;
  }
}

h5,
.h5 {

  @media only screen and (min-width: theme('screens.md')) {
  }
}

h6,
.h6 {

  @media only screen and (min-width: theme('screens.md')) {
  }
}

p,
.p {
  font-size: 15px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: -.3px;
  color: theme('colors.secondary');

  @media only screen and (min-width: theme('screens.md')) {
    font-size: 20px;
    letter-spacing: -.4px;
  }

  &.p--lg {
    font-size: 15px;
    line-height: 142%;
    font-weight: 500;
    letter-spacing: -.2px;

    @media only screen and (min-width: theme('screens.md')) {
      font-size: 30px;
      letter-spacing: -.4px;
    }
  }

  &.p--md {
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    letter-spacing: -.3px;

    @media only screen and (min-width: theme('screens.md')) {
    }
  }

  &.p--md2 {
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    letter-spacing: -.3px;
  }

  &.p--sm {
    font-size: 12px;
    line-height: 150%;
    font-weight: 500;
    letter-spacing: -.2px;
  }
}

b,
strong {
  font-weight: 700;
}

a {
  font-size: 20px;
  line-height: 150%;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: -.4px;
  color: theme('colors.gray');

  &:hover {
    text-decoration: none;
  }
}

hr {
  color: #47474a;
}

ul {
  margin: 0 0 0 26px;
  list-style-type: disc;

  li {
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    letter-spacing: -.3px;
    color: theme('colors.secondary');

    @media only screen and (min-width: theme('screens.md')) {
      font-size: 20px;
      letter-spacing: -.4px;
    }
  }
}

ol {
  margin: 0 0 0 26px;
  list-style: decimal;

  li {
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    letter-spacing: -.3px;
    color: theme('colors.secondary');

    @media only screen and (min-width: theme('screens.md')) {
      font-size: 20px;
      letter-spacing: -.4px;
    }
  }
}

table {
  width: 100%;

  th {
    padding: 24px;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    text-align: left;
    letter-spacing: -.3px;
    color: theme('colors.secondary');
    background: #202328;
    border: 2px solid #47474a;

    @media only screen and (min-width: theme('screens.md')) {
      font-size: 20px;
      letter-spacing: -.4px;
    }
  }

  td {
    padding: 24px;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    text-align: left;
    letter-spacing: -.3px;
    color: theme('colors.secondary');
    border: 2px solid #47474a;

    @media only screen and (min-width: theme('screens.md')) {
      font-size: 20px;
      letter-spacing: -.4px;
    }
  }
}

tr {
}

th {
}

td {
}

input {

  &:focus {
    outline: none;
  }
}

textarea {

  &:focus {
    outline: none;
  }
}

select {
}

button {
  padding: 0;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input[type='submit'] {
  border: 0;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

div,
a,
a:active,
a:focus {
  outline: none;
}
